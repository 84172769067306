import { Container } from "react-bootstrap";
import Layout from "../../layout/Layout";

const TermAndConditions = () => {
  return (
    <> 
      <Layout>
        <div className={ localStorage.length ? "":'charity_publicBox'}>
          <Container>
            <div className="tnc">
              <div className="row">
                <div className="text-light">
                  <div className="header"> 
                    <h3>
                      <b>
                        <u style={{color:"#E79E1F"}}>
                          BEYONDBOX TERMS OF SERVICE
                        </u>
                      </b>
                    </h3>
                  </div>
                  <div className="header">
                    <h5>
                      <b> 
                        <u>
                          These Terms of Service (“Terms of Service”) are effective as of 1 May 2023 “Effective Date”
                        </u>
                      </b>
                    </h5>
                  </div>
                  <div className="header">
                    <p>
                      <h4><b>READ THESE TERMS CAREFULLY BEFORE USING THE SOFTWARE APPLICATION OR THE WEBSITE (COLLECTIVELY THE “PLATFORM”). YOUR CONTINUED USE OF THE PLATFORM INDICATES THAT YOU HAVE BOTH READ AND ACCEPT THESE TERMS. YOU CANNOT USE THE PLATFORM IF YOU DO NOT ACCEPT THESE TERMS. ALL SECTIONS OF THESE TERMS ARE APPLICABLE TO ALL USERS UNLESS THE SECTION EXPRESSLY STATES OTHERWISE.</b></h4>
                    </p>
                  </div>
                  <div className="list">
                    <ul> 
                      <li className="mainPoint">
                        <h4> 1. <b>Introduction</b> </h4>
                        <ul>
                          <li className="paragraph">
                            1.1. <span> <p>Both the Software Application and the Website (collectively the “Platform”) through which the Services are provided are operated and/or owned by BeyondBox (Pty) Ltd t/a BeyondBox (bearing registration number: 20222/488557/07) (hereinafter referred to as "BeyondBox ", "we", “our" or "us"). The Terms of Service are entered into by and between BeyondBox and the User. Any reference to "BeyondBox ", "we", "our" or "us", shall include our employees, officers, directors, representatives, agents, shareholders, affiliates, subsidiaries, holding companies, related entities, advisers, sub-contractors, service providers and suppliers.</p></span> 
                          </li>
                          <li className="paragraph">
                            1.2. <span><p>These Terms of Service, including any document incorporated by reference herein, including, but not limited to the Privacy Policy (collectively, the "Terms") apply to any User who uses the Services, accesses, refers to, views and/or downloads any information or material made available through the Platform for whatever purpose (hereinafter referred to as “User”, "you", "your", “he”, “she”, “his”, “him” or “her”). </p></span> 
                          </li>
                          <li className="paragraph">
                            1.3.  <span><p>Accessing and/or use of the Platform after the Effective Date will signify that you have read, understand, accept, and agree to be bound, and are bound, by the Terms, in your individual capacity. Further, you represent and warrant that you are a Competent Person (as defined in the Protection of Personal Information Act, 4 of 2013, as amended (“POPI Act”)).</p></span>
                          </li>
                          <li className="paragraph">
                            1.4. <span> <p>We do not accept any Users, or representatives of a User under the age of 18.  Should a User be younger than the age of 18, they may only make use of the Services if their parent or guardian has consented to the terms hereof.</p></span>
                          </li>
                          <li className="paragraph">
                            1.5. <span><p>To the extent permitted by applicable law, we may modify the Terms as contained herein and emailed to you on registration as a User.  In the modification of the Terms a User (or the User’s guardian or parent as the case may be) will receive an email from us advising once the modifications have been made.  We do require acceptance by the User prior to the User’s continued use of the Platform, which consent shall be construed as a User’s consent to the modified, amended or updated Terms.  A User’s only remedy, should they not agree to the modifications, is to refuse acceptance of the amended or updated Terms, thereby preventing your use of the Platform.</p></span>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <h4>  2. <b>Terminology</b></h4>
                        <ul>
                          <li className="paragraph">
                            2.1. <span><p><b> “Browser”</b> shall mean any person who through our website or by other means accesses the Platform and who has no intention of using, or has not used the Services offered by us;</p></span>
                          </li>
                          <li>
                            <div className="paragraph">
                            2.2. <span> <p><b>“Content”</b> “Content” shall mean photos, media, videos, voice notes, messages, text messages, pictures music playlists, documents, various natures of recordings, music and the like uploaded by a User when creating a Digital Box as part of the Services.  It shall however specifically exclude:</p></span>
                            </div>
                            <ul style={{marginLeft:40}}>
                              <li>
                                2.2.1. <span>Sensitive Information;;</span>
                              </li>
                              <li>
                                2.2.2. <span>a last will and testament;</span>
                              </li>
                              <li>
                                2.2.3. <span>a codicil to a last will and testament;</span>
                              </li>
                              <li>
                                2.2.4. <span>list of wishes in respect of a User’s estate; </span>
                              </li>
                              <li className="paragraph">
                                2.2.5. <span>a list of bequests in respect of a User’s movable or immovable property; or</span>
                              </li>
                              <li className="paragraph">
                                2.2.6. <span>2.2.6.	any form of Content which could be construed as a last will and testament or instructions to supersede/nullify an existing legal document.</span>
                              </li>
                            </ul>
                          </li>
                          <li className="paragraph">
                            2.3. <span><p><b>“Digital Box”</b> shall mean either a Private Digital Box or a Public Digital Box (or both) into which Content may be uploaded by a User;</p></span>
                          </li>
                          <li className="paragraph">
                            2.4. <span> <p><b>“License”</b> shall mean the non-transferrable, non-exclusive, limited, and revocable license to access the Platform as granted by us to the User as more fully set out in clause 3 below, which Platform for the avoidance of doubt, shall be construed as our intellectual property;</p></span>
                          </li>
                          <li className="paragraph">
                              2.5. <span><p><b>“Next of Kin”</b> shall mean the person nominated by the User during the registration process, whose duties and obligations are those set out in clause 6.1 below;</p> </span>
                            </li>
                            <li>
                              2.6. <span><b>“Party”</b> or <b>“Parties”</b> shall mean BeyondBox and/or the User as the context implies;</span>
                            </li>
                            <li className="paragraph">
                              2.7. <span> <p><b>“Personal Information”</b> shall mean the personal information as required from a Browser in order to register for the Services as defined in the POPI Act;</p></span>
                            </li>
                            <li className="paragraph">
                              2.8. <span> <p><b>“Plan”</b> shall mean either the Free Plan, or the Premium Plan, which a Browser shall be able to select during the Registration Process, the details of which are set out in clause 8 below;</p></span>
                            </li>
                            <li>
                              2.9. <span><b> “Platform”</b> shall comprise both the software application and the website owned by us as subscribed to by a User;</span>
                            </li>
                            <li className="paragraph">
                              2.10. <span><p><b>“Private Digital Box”</b> shall mean the space allocated to the User on the Platform where the Content will be stored, and which Content will be made available to only designated Recipients;</p></span>
                            </li>
                            <li className="paragraph">
                              2.11. <span><p><b> “Public Digital Box”</b> shall mean the space allocated to Users collectively on the Platform under the Box Records tab, which specified Content shall be made available to all Browsers or Users;</p></span>
                            </li>
                            <li className="paragraph">
                              2.12. <span><p><b>“Recipient/s”</b> shall mean the person or persons selected by the User to receive Content or a specific portion thereof from the User’s Private Digital Box;</p></span>
                            </li>
                            <li className="paragraph">
                              2.13. <span><p><b>“Registration Process”</b> refers to the process to be followed by a Browser through the Platform in order to enable him or her to make use of the Services and a Plan and thus make the transition from a Browser to a User as set out in clause 10  below;</p></span>
                            </li>
                            <li>
                              2.14. <span><b>“Sensitive Information”</b> means sensitive information such as:</span>
                              <ul style={{marginLeft:40}}>
                                <li>
                                  2.14.1. <span>Financial information such as account numbers, card numbers or pin codes;</span>
                                </li>
                                <li>
                                  2.14.2. <span>Confidential company or business information;</span>
                                </li>
                                <li>
                                  2.14.3. <span>Identity, Social Security or passport documents;</span>
                                </li>
                                <li>
                                  2.14.4. <span>Passwords, credentials or security keys;</span>
                                </li>
                                <li>
                                  2.14.5. <span>Documentation or media containing sensitive information;</span>
                                </li>
                                <li>
                                  2.14.6. <span>Medical records or health information; and</span>
                                </li>
                                <li>
                                  2.14.7. <span>Legal documents containing sensitive details;</span>
                                </li>
                              </ul>
                            </li>
                            <li>
                              2.15. <span><b>“Services”</b> shall refer to the Services provided by us to the User through the Platform as set out in clause 5 below;</span>
                            </li>
                            <li className="paragraph">
                              2.16. <span><p><b>“Terms”</b> shall mean this Terms of Service as read together with the Privacy Policy, which Privacy Policy may be found here: http://www.beyondbox.org; and</p></span>
                            </li>
                            <li>
                              2.17. <span> <b>“User”</b> shall mean the Browser who completes the Registration Process in order to make use of the Services and a Plan.</span>
                            </li>
                            <li className="paragraph">
                              2.18. <span><p>Any use of the above terminology or other words in the singular, plural, capitalisation and/or he/she or they, are taken as interchangeable and therefore as referring to the same.</p></span>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <h4> 3.<b>Your agreement to these Terms</b></h4>
                          <ul>
                            <li className="paragraph">
                              3.1. <span><p>Subject to, and on the basis of a Browser’s acceptance of the Terms, we grant to you a limited, revocable, non-transferable license to access and use the Platform in accordance with the Terms.</p></span>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <h4>4.<b>Commencement and Duration</b> </h4>
                          <ul>
                            <li className="paragraph">
                              4.1. <span><p>Save for what is stated in clause 16 below, the Terms shall apply to a User from the date that same are accepted until such time as the Services are cancelled by a User, alternatively if not cancelled by a User then within 30 (thirty) days of a User’s demise, subject to only those terms BeyondBox continuing in existing, which terms are necessary for BeyondBox to fulfil its obligations in terms hereof.</p></span>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <h4>5.<b>Services</b></h4>
                          <ul>
                            <li>
                              5.1. <span> BeyondBox, through the Platform, provides a Service allowing:</span>
                              <ul style={{marginLeft:40}}>
                                <li className="paragraph">
                                  5.1.1. <span><p>Users to create Digital Boxes and upload Content, which Content shall be released to the Recipients on the User’s demise, save as where stated otherwise in clause 7 below; and with</p></span>
                                </li>
                                <li className="paragraph">
                                  5.1.2. 
                                  <span><p>the Recipient (alternatively the Recipient’s guardian or parent if the Recipient is under the age of majority) to receive Content upon the User’s demise, </p>
                                    <p>with the nature of the Content being dependant on the Plan selected by the User.</p>
                                  </span>
                                </li>
                              </ul>
                            </li>
                            <li>
                              5.2. <span>Private Digital Boxes shall include the following types or boxes:</span>
                              <ul style={{marginLeft:40}}>
                                <li className="paragraph">
                                  5.2.1. <span><p>BeyondBox</p>
                                  <p>Capture your final goodbyes, boundless love, timeless wisdom, and heartfelt guidance. Fill this box with precious memories, favourite quotes, songs, and messages that will comfort and inspire your loved ones forever.</p></span>
                                </li>
                                <li className="paragraph">
                                  5.2.2. 
                                  <span><p>Info Box</p>
                                    <p>Empower your loved ones with vital information to seamlessly manage your estate and affairs. Include the location of your will, financial advisor details, and crucial instructions to ease their journey after your passing.</p>
                                  </span>
                                </li>
                                <li className="paragraph">
                                  5.2.3.	 
                                  <span><p>Our Family</p>
                                    <p>Celebrate your family’s rich tapestry, honouring members both past and present. Share stories, cherished values, heritage, and photos of beloved family members, creating a legacy that will endure through generations.</p>
                                  </span>
                                </li>
                                <li className="paragraph">
                                  5.2.4.	 
                                  <span><p>Vision Box</p>
                                    <p>Illuminate your life's dreams and aspirations. Document your goals, hopes, and achievements, creating a beacon of inspiration and motivation for yourself and those who follow in your footsteps.</p>
                                  </span>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <h4>6.<b>Release Process</b></h4>
                          <ul>
                            <li>
                              6.1. <span>Upon the demise of a User the Next of Kin, trustee or executor shall provide us with a copy of the User’s death certificate or notice of death.</span>
                            </li>
                            <li className="paragraph">
                              6.2. <span><p>Once we have satisfied ourselves that the death certificate is legitimate and that there is no reason to withhold Services as set out in clause 7 below, the Contents of the Digital Box/es will be released to the specified Recipients.</p></span>
                            </li>
                            <li className="paragraph">
                              6.3. <span><p>In order to facilitate same, each Recipient will receive a communication from us and that they are required to sign up to the Platform in order to gain access to their allocated Content.</p></span>
                            </li>
                          </ul>
                        </li>
                        <li id="terms">
                          <h4>7. <b>Withholding of Services</b></h4>
                          <ul>
                            <li className="paragraph">
                              7.1. <span> <p>In the event that it transpires that a User has committed suicide, BeyondBox reserves the right to withhold the release of all of your Content and delete same from the Platform. No refunds will be given in such instances.</p></span>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <h4>8. <b>Plans</b></h4>
                          <ul>
                            <li>
                              <div className="paragraph">
                              8.1. <span><p>The Services shall comprise of one of two Plans, namely Free or Premium, as recorded in more detail below.  During the Registration Process, the User shall be able to select which Plan he/she would like to register for.</p></span>
                              </div>
                              <ul style={{marginLeft:40}}>
                                <li>
                                  8.1.1.<b style={{fontSize:18}}>Free Plan</b>:
                                  <ul>
                                    <li>
                                      8.1.1.1. <span>There is no cost associated with this Plan, and it allows a User to:</span>
                                      <ul style={{marginLeft:70}}>
                                        <li className="mt-4">
                                          8.1.1.1.1. <span>create 1 (one) Private Digital Box;</span>
                                        </li>
                                        <li>
                                          8.1.1.1.2. <span>Box Types : One BeyondBox</span>
                                        </li>
                                        <li className="paragraph">
                                          8.1.1.1.3. <span><p>8.1.1.1.3.	appoint a maximum of 1 (one) Recipient per Private Digital Box;</p></span>
                                        </li>
                                        <li>
                                          8.1.1.1.4. <span>have Content comprising of text messages, video, images, audio and documents, and not exceeding 50 (fifty) megabytes;</span>
                                        </li>
                                        <li>
                                          8.1.1.1.5. <span>create 1 (one) Public Digital Box;</span>
                                        </li>
                                        <li className="paragraph">
                                          8.1.1.1.6. <span><p>be a Recipient of Content from other Users;</p></span>
                                        </li>
                                        <li>
                                          8.1.1.1.7. <span>have access to Content to which he/she is a Recipient for a period of 12 (twelve) months; and </span>
                                        </li>
                                        <li>
                                          8.1.1.1.8. <span>receive in application adverts.</span>
                                        </li>
                                      </ul>
                                      <div className="paragraph">
                                      8.1.1.2. <span><p>This Plan shall continue indefinitely, however we reserve the right to cancel the Services in the event that a User has not utilised their Plan for a consecutive 24 (twenty four) month period from the date of acceptance of these Terms. </p></span>
                                      </div>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                  8.1.2.<b style={{fontSize:18}}>Premium Plan</b>:
                                  <ul>
                                    <li>
                                      8.1.2.1. <span>monthly subscription; or</span>
                                    </li>
                                    <li>
                                      8.1.2.2. <span>annual subscription</span>
                                    </li>
                                    <li>
                                      8.1.2.3. <span> This Plan allows a User to :</span>
                                      <ul style={{marginLeft:70}}>
                                        <li className="mt-4">
                                          8.1.2.3.1. <span>create an unlimited amount of Private Digital Boxes;</span>
                                        </li>
                                        <li>
                                          8.1.2.3.2. <span>Box Types: BeyondBox, Vision-Box, Info-Box, Our Family Box</span>
                                        </li>
                                        <li>
                                          8.1.2.3.3. <span> appoint a maximum of 5 (five) Recipients per Private Digital Box;</span>
                                        </li>
                                        <li className="paragraph">
                                          8.1.2.3.4. <span><p> upload Content into each Digital Box up to a total <b style={{fontSize:"18px"}}>Premium  Plan</b> limit of 2 (two) gigabytes, with the option to purchase more space if required;</p></span>
                                        </li>
                                        <li>
                                          8.1.2.3.5. <span> create 1 (one) Public Digital Box;</span>
                                        </li>
                                        <li>
                                          8.1.2.3.6. <span> be a Recipient of Content from other Users;</span>
                                        </li>
                                        <li>
                                          8.1.2.3.7. <span> have access to Content to which he/she is a Recipient for an unlimited period; and</span>
                                        </li>
                                        <li>
                                          8.1.2.3.8. <span> cancel the Service at any time.</span>
                                        </li>
                                      </ul>
                                    </li>
                                  </ul>
                                </li>
                                <li>
                                8.1.3.<b>Downgrade of Plan</b>
                                <ul className="mt-4">
                                  <li>
                                    8.1.3.1. <span> A User may downgrade from a Premium Plan to a Free Plan at any time through the Platform.</span>
                                  </li>
                                  <li className="paragraph">
                                    8.1.3.2. <span><p> In so doing, all Content save for text messages will be available for a period of no more than 30 (thirty) days before being deleted by us.</p></span>
                                  </li>
                                  <li className="paragraph">
                                    8.1.3.3. <span><p>A User, when downgrading would need to bring the Content in line with that available in respect of the Free Plan, and which would result in certain Private Boxes having to be deleted by the User.</p></span>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <h4>9.<b>Payment in respect of the Premium Plan</b></h4>
                        <ul>
                          <li className="paragraph mt-4">
                            9.1. <span><p> We are committed to providing secure online payment facilities. All transactions are encrypted using appropriate encryption technology.</p></span>
                          </li>
                          <li>
                            9.2 <span> Payment may be made by:</span>
                            <ul style={{marginLeft:40}}>
                              <li className="paragraph mt-4">
                                9.2.1. <span>debit or credit card (EFT).  When making payment, we may require additional information in order to authorise and/or verify the validity of payment. In such cases we are entitled to suspend access to the Services until such time as the additional information is received. If we do not receive authorisation the Premium Plan will lapse. A User further warrants that his/her credit or debit card has sufficient available funds to cover all the costs incurred in respect of the payment.  When making payment a User will be directed to a link to a secure site for payment.</span>
                              </li>
                              <li className="paragraph">
                                9.2.2. <span>Payfast – the User is encouraged to consult the Payfast website located at <a target="_blank" href="www.payfast.io">www.payfast.io</a> in order to familiarise himself/herself with the security protocols and other policies in place and which may apply to any transaction conducted through Payfast.  Any charges levied by Payfast as a result of using this payment method shall be for the User’s account, and no compensation will be provided in respect of same.</span>
                              </li>
                              <li className="paragraph">
                                9.2.3. <span>	Paypal - the User is encouraged to consult the Payfast website located at <a target="_blank" href="www.paypal.com">www.paypal.com</a> in order to familiarise himself/herself with the security protocols and other policies in place and which may apply to any transaction conducted through Paypal.  Any charges levied by Paypal as a result of using this payment method shall be for the User’s account, and no compensation will be provided in respect of same.</span>
                              </li>
                              <li className="paragraph">
                                9.2.4. <span>In-App purchase for Apple - the User is encouraged to consult the  website located at <a target="_blank" href="www.apple.com">www.apple.com</a> in order to familiarise himself/herself with the security protocols and other policies in place and which may apply to any transaction conducted therethrough.  Any charges levied as a result of using this payment method shall be for the User’s account, and no compensation will be provided in respect of same.</span>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <h4>10.<b>Registration Process</b></h4>
                        <ul>
                          <li>
                            10.1. <span> In order to access the Services, a Browser is required to register for same through the Platform.</span>
                          </li>
                          <li>
                            10.2. <span> During the registration process the User will be required to provide us with:</span>
                            <ul style={{marginLeft:50}}>
                              <li className="mt-4">
                                10.2.1. <span> the Personal Information as set out in the Privacy Policy;</span>
                              </li>
                              <li className="paragraph">
                                10.2.2. <span>the name and contact details of at least one Next of Kin, the responsibility of whom it will be to advise us of the User’s demise and provide us with an official notice of death as accepted in law in the country in which you resided at the time of your death;</span>
                              </li>
                              <li className="paragraph">
                                10.2.3. <span>the name, contact number, e-mail address and date of birth of each Recipient of the Contents of a Private Digital Box, alternatively the name, contact number, e-mail address and date of birth of the parent or guardian of a Recipient in the event of such Recipient not having reached the age of majority; and</span>
                              </li>
                              <li className="paragraph">
                                10.2.4. <span>clear instructions should any Content be required to be delayed after death and made available to a Recipient at a later date.</span>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <h4>11.<b>Warranties by the User</b></h4>
                        <ul>
                          <li className="paragraph mt-4">
                            11.1. <span>The User warrants and represents that all of his/her Personal Information together with that of his/her chosen Next of Kin and the Recipients is and shall remain accurate, true and correct and that the User will update the Personal Information held by us to reflect any changes as soon as possible. </span>
                          </li>
                          <li>
                            11.2. <span>The User further warrants that when registering for and utilising the Services that:</span>
                            <ul style={{marginLeft:50}}>
                              <li className="paragraph mt-4">
                                11.2.1. <span>they have reached the age of majority, alternatively have the consent of a parent or guardian to enter into the Terms and make use of the Services;</span>
                              </li>
                              <li>
                                11.2.2. <span>they are not impersonating any person; </span>
                              </li>
                              <li>
                                11.2.3. <span>they are not violating any applicable law regarding use of personal or identification information;</span>
                              </li>
                              <li>
                                11.2.4. <span> they shall not use the Platform or the Services for any objectionable or unlawful purpose;</span>
                              </li>
                              <li className="paragraph">
                                11.2.5. <span>they shall not upload any offending Content, and understand that Content uploaded to a Public Digital Box will be moderated by us prior to being made available on the Platform;</span>
                              </li>
                              <li>
                                11.2.6. <span>they shall not upload any Content comprising or including Sensitive Information;</span>
                              </li>
                              <li>
                                11.2.7. <span>they shall not use the Services for promotion or solicitation purposes;</span>
                              </li>
                              <li>
                                11.2.8. <span>they shall not use the Services for any purpose that is illegal or prohibited by the Terms;</span>
                              </li>
                              <li>
                                11.2.9. <span> they shall not use the Service for any harmful or nefarious purpose;</span>
                              </li>
                              <li>
                                11.2.10. <span> they shall not use the Service in an attempt to damage our reputation in any manner;</span>
                              </li>
                              <li className="paragraph">
                                11.2.11. <span> they shall not bully, stalk, intimidate, assault, harass, mistreat or defame any person (irrespective of whether such third party is a User or not);</span>
                              </li>
                              <li className="paragraph">
                                11.2.12. <span>11.2.12.	they shall not post any Content that violates any User or third party rights, including but not limited to rights of publicity, privacy, copyright, trademark or any other intellectual property or contract right;</span>
                              </li>
                              <li>
                                11.2.13. <span> they shall not obtain the password of another User for any reason whatsoever;</span>
                              </li>
                              <li className="paragraph">
                                11.2.14. <span>they shall not obtain and utilise personal information of another User, Next of Kin or Recipient in a dishonest or misleading manner, and for commercial or illegal purposes, without such User’s consent first having been obtained; </span>
                              </li>
                              <li>
                                11.2.15. <span>they shall not hack into another User’s Digital Box;</span>
                              </li>
                              <li>
                                11.2.16. <span> they shall not send to us spam mail, or make use of other unsolicited mass e-mailing techniques; and</span>
                              </li>
                              <li className="paragraph">
                                11.2.17. <span>they shall not introduce any virus, worm, trojan horse, malicious code or other program which may damage computers or other electronic devices through communication with us or other Users.</span>
                              </li>
                            </ul>
                          </li>
                          <li>
                            11.3. <span>A User may also not:</span>
                            <ul style={{marginLeft:50}}>
                              <li className="paragraph mt-4">
                                11.3.1. <span>remove or alter our copyright notices or other means of identification including any watermarks, that are used in respect of the Platform; and/or</span>
                              </li>
                              <li className="paragraph">
                                11.3.2. <span>distribute or publish any part of the information or content included in the Services on any publicly accessible electronic network, including without limitation the internet and the world wide web, Facebook, YouTube, or otherwise publish, broadcast or display any such information in public. </span>
                              </li>
                            </ul>
                          </li>
                          <li className="paragraph">
                            11.4. <span>A User understands and agrees that he/she is solely responsible for compliance with any and all laws, rules and regulations that may apply to their use of the Platform or the Services.</span>
                          </li>
                          <li className="paragraph">
                            11.5. <span>Further and insofar as the Registration Process is concerned, the User warrants that the password submitted during the Registration Process shall not be disclosed to any third party, and agrees that once the correct password relating to the User’s Profile has been entered, the User shall be solely responsible for all activities that occur in respect of the Services. Users are responsible for ensure that they create suitably strong passwords.</span>
                          </li>
                          <li className="paragraph">
                            11.6. <span> In the event that a User suspects that a third party has gained access to their Profile we must immediately be contacted on <a href="mailto:info@beyondbox.org">info@beyondbox.org</a> .</span>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <h4>12.<b>Warranties by BeyondBox</b></h4>
                        <ul>
                          <li className="mt-4">
                            12.1. <span>We make no representation or warranty (express or implied) that the Services or Platform will:</span>
                            <ul style={{marginLeft:50}}>
                              <li className="mt-4">
                                12.1.1. <span>meet a User’s needs;</span>
                              </li>
                              <li>
                                12.1.2. <span>be accessible at all times;</span>
                              </li>
                              <li>
                                12.1.3. <span>be accurate, complete or current; or</span>
                              </li>
                              <li>
                                12.1.4. <span>be free from viruses.</span>
                              </li>
                            </ul>
                          </li>
                          <li className="paragraph">
                            12.2. <span>Except for any express warranties in the Terms the Services are provided “as is”.  BeyondBox makes no other warranties, express or implied, statutory or otherwise, including but not limited to warranties of fitness for a particular purpose or noninfringement. We do not provide any warranties against viruses, spyware or malware that may be installed on your device/s as a result of you accessing the Platform or the Services.</span>
                          </li>
                          <li className="paragraph">
                            12.3. <span>We do not warrant that the use of the Platform and/or the Services will be uninterrupted or error free, nor do we warrant that we will review information for accuracy or that we will preserve or maintain Personal Information without loss.</span>
                          </li>
                          <li className="paragraph">
                            12.4. <span>We shall not be liable for delays, interruptions, service failures or other problems inherent in use of the internet and electronic communications or other systems outside of our reasonable control. While a User may have statutory rights, the duration of any such statutorily warranties, will be limited to the shortest period to the extent permitted by required law.</span>
                          </li>
                          <li className="paragraph">
                            12.5. <span>We shall not take responsibility for any consequences arising out of the release of Content provided by a User or released by us after a User’s demise through the Services.</span>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <h4>13.<b>Protection of Personal Information</b></h4>
                        <ul>
                          <li className="paragraph mt-4">
                            13.1. <span>We undertake as far as is possible, to protect each User’s Personal Information in accordance with our Privacy Policy, except as may be necessary in order to perform all such Services as required in respect of the Terms.</span>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <h4>14.<b>Links to other Websites</b></h4>
                        <ul>
                          <li className="paragraph">
                            14.1. <span>In making use of the Platform and the Services there may be advertisements and promotions offered by third parties, together with links or portals to other websites. We have no control over websites operated by third parties and the User agrees that we are not responsible for and will have no liability in connection with a User’s access to or use of any third-party website.</span>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <h4>15.<b>Limitation of Liability and Indemnity</b></h4>
                        <ul>
                          <li className="mt-4">
                            15.1. <span>The Platform and Services shall be used entirely at a User’s own risk.</span>
                          </li>
                          <li className="paragraph">
                            15.2. <span>Although we take up-to-date security measures in an attempt to safeguard the protection and integrity of the Platform, and the Content in the form of Amazon Web Services (RDS), SSL, end to end encryption, 2 factor authentication and one time pin verification together with password protection, we cannot and do not guarantee that the Platform is at all times free of viruses or that no unlawful monitoring and/or access from the Platform takes place.  The User’s access to and use of the Platform and Services remains solely at the User’s own risk and the User is encouraged to take his/her own precautions accordingly.</span>
                          </li>
                          <li className="paragraph">
                            15.3. <span>We are not responsible for, and the User agrees that we will have no liability in relation to, the use of and conduct in connection with the Platform, or any other person’s use of or conduct in connection with the Platform or the Services, in any circumstance..</span>
                          </li>
                          <li className="paragraph">
                            15.4. <span>We cannot guarantee or warrant that any file downloaded from the Platform or delivered to you via email will be free of infection or virus, worms, trojan horses or other code that has contaminating or destructive qualities. A User is responsible for implementing appropriate processes, systems and procedures to protect him/her from this type of issue. We also cannot guarantee that the Platform will not be subject to cyberattacks or threats.</span>
                          </li>
                          <li className="paragraph">
                            15.5. <span>A User indemnifies us, and agrees to keep us indemnified, from and against any claim, loss, damage, cost or expense that we may suffer or incur as a result of or in connection with a User’s use of or conduct in connection with the Platform or the Services, including any cyberattacks or cyber threats and breach by a User of these terms or any applicable law or licensing requirements.</span>
                          </li>
                          <li className="paragraph">
                            15.6. <span>To the maximum extent permitted by law we exclude all implied representations and warranties which, but for these terms, might apply in relation to a User’s use of the Platform or the Services.</span>
                          </li>
                          <li className="paragraph">
                            15.7. <span>To the extent that our liability cannot be excluded by law, our maximum liability, whether in contract, equity, statute or delict (including negligence), to a User will be limited to the minimum amount imposed by such law.</span>
                          </li>
                          <li className="paragraph">
                            15.8. <span>Notwithstanding anything to the contrary in these terms, in no circumstances will we be liable for any indirect, punitive or consequential loss or damages, loss of income, profits, goodwill, data, contracts, use of money or any loss or damages arising from or in any way connected to interruption of the Services of any type, the conduct or Content of other Users or third parties, or the unathorised access, use of or alteration of Content even in cases where we have been advised of the possibility of such damages whether in delict, contract or otherwise.</span>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <h4>16.<b>Breach</b></h4>
                        <ul>
                          <li className="mt-4">
                            <div className="paragraph">
                            16.1. <span>If either Party (“the Defaulting Party”) breaches any provision of these Terms and remains in breach for 14 (fourteen) days after receipt of written notice from the other Party (the “Aggrieved Party”) requiring him/her to rectify the breach, the Aggrieved Party shall be entitled at his/her option (and without prejudice to any other rights that he/she may have at law):</span>
                            </div>
                            <ul style={{marginLeft:50}}>
                              <li className="mt-4">
                                16.1.1. <span>to sue for specific performance of the Defaulting Party's obligations under these Terms;</span>
                              </li>
                              <li>
                                16.1.2. <span>to cancel these Terms by notice in writing to the Defaulting Party (subject to clause 16.2  below); or</span>
                              </li>
                              <li>
                                16.1.3. <span>in either event without prejudice to the Aggrieved Party's right to claim damages.</span>
                              </li>
                            </ul>
                          </li>
                          <li className="paragraph">
                            16.2. <span>Either Party so aggrieved by any breach of any provision of these Terms by the other Party shall only be entitled to cancel these Terms if such breach is a material breach of the provisions of these Terms which goes to the root of these Terms and the Defaulting Party fails to remedy such breach during the 14 (fourteen) day period referred to in clause 15.1 above.</span>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <h4>17.<b>Content</b></h4>
                        <ul>
                          <li className="paragraph mt-4">
                            17.1. <span>We do not, nor are we under any obligation or duty to any User to monitor, consider, evaluate, assess, review, screen, censor or remove any Content submitted by a User, save for that made available in a Public Digital Box, but reserve our rights to do so should the need arise. </span>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <h4>18.<b>Copyright</b></h4>
                        <ul>
                          <li className="paragraph mt-4">
                            18.1. <span>The contents of the Platform is the property BeyondBox, unless specified otherwise, and is protected by South African and international copyright laws. Furthermore, the compilation (meaning the collection, arrangement, and assembly) of all content and Content on the Platform and/or the Services, is our property or shall be transferred to us on upload, unless credit is attributed to the author thereof, and is, likewise, protected by South African and international copyright laws.</span>
                          </li>
                          <li className="paragraph">
                            18.2. <span>Except as stated in the Terms, none of the contents may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means, including, but not limited to, electronic, mechanical, photocopying, recording, or otherwise, except as permitted by the fair use privilege under the South African copyright laws or without our prior written permission, which should such consent be provided, we reserve our right to withdraw such consent at any stage, in our sole and absolute discretion.</span>
                          </li>
                          <li className="paragraph">
                            18.3. <span>Users are expressly prohibited to “mirror” any content, contained on the Platform, or on any other platform unless our prior written permission is obtained, which should such consent be provided, we reserve our right to withdraw at any stage, in our sole and absolute discretion.</span>
                          </li>
                          <li className="paragraph">
                            18.4. <span>The User is granted a limited, revocable, and non-exclusive right to create a hyperlink to the Platform, so long as the link does not portray us, our affiliates or Services in a false, misleading, derogatory, or otherwise offensive manner. </span>
                          </li>
                          <li className="paragraph">
                            18.5. <span>All trademarks and copyrights, as also any other intellectual property rights, in and to any of the content of the Platform are our exclusive property.</span>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <h4>19.<b>Intellectual Property</b></h4>
                        <ul>
                          <li className="paragraph mt-4">
                            19.1. <span>The User undertakes not to attempt to decipher, decompile, disassemble or reverse engineer any of the software or code comprising or in any way making up a part of the Platform including any algorithm used by us.</span>
                          </li>
                          <li className="paragraph">
                            19.2. <span>We own or are licensed to use all intellectual property on the Platform.  A User may not use any of our intellectual property for any purpose other than as may be required to use the Platform for its intended purpose.</span>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <h4>20.<b>Miscellaneous</b></h4>
                        <ul>
                          <li className="mt-4">
                            20.1 <b>Dispute Resolution and Mediation</b>
                            <ul>
                              <li className="paragraph">
                                20.1.1. <span>Save as otherwise contained herein, should any dispute arise between the Parties in connection with these Terms, our senior representative together with the User shall attempt on the basis of good faith to settle such dispute by way of negotiation.</span>
                              </li>
                              <li className="paragraph">
                                20.1.2. <span>If either Party provides written notification to the other that such attempt has failed then each Party shall attempt to agree upon the appointment of a suitably qualified mediator, within 10 (ten) days of such dispute being referred.</span>
                              </li>
                              <li className="paragraph">
                                20.1.3. <span>If agreement is not reached as to the appointment of such mediator within 10 (ten) days after either Party has in writing called for the appointment of a mediator, or where an appointment has been agreed upon and such mediator is not able to mediate a resolution of such dispute within 21 (twenty one) days after such appointment then any Party may give written notice to the other Party referring the dispute to arbitration in accordance with the provisions of this clause 20.1.</span>
                              </li>
                              <li>
                                20.1.4. <span>The arbitration shall be: </span>
                                <ul style={{marginLeft:60}}>
                                  <li>
                                    20.1.4.1. <span>held in Pietermaritzburg;</span>
                                  </li>
                                  <li>
                                    20.1.4.2. <span>conducted in the English language;</span>
                                  </li>
                                  <li>
                                    20.1.4.3. <span>held before a single arbitrator;</span>
                                  </li>
                                  <li className="paragraph">
                                    20.1.4.4. <span>subject to the provisions of this clause 20.1, conducted in accordance with the Arbitration Foundation of Southern Africa (AFSA) Rules; and</span>
                                  </li>
                                  <li className="paragraph">
                                    20.1.4.5. <span>held as soon as is reasonably practicable in the circumstances and with a view to it being completed within 21 (twenty one) days of the date of the Arbitration Notice.</span>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                20.1.5. <span>The arbitrator shall be if the question in issue is:</span>
                                <ul>
                                  <li className="paragraph mt-4">
                                    20.1.5.1. <span> primarily an accounting matter, an independent accountant advocate with no less than 10 (ten) years' experience, agreed upon between the Parties;</span>
                                  </li>
                                  <li className="paragraph">
                                    20.1.5.2. <span>primarily a legal matter, an independent practicing senior advocate with no less than 10 (ten) years' standing, agreed upon between the Parties; and</span>
                                  </li>
                                  <li>
                                    20.1.5.3. <span>any other matter, a suitably qualified independent person, agreed upon between the Parties.</span>
                                  </li>
                                </ul>
                              </li>
                              <li className="paragraph">
                                20.1.6. <span>If the Parties cannot agree upon a particular arbitrator under the provisions of clause 20.1.5 within 7 (seven) business days after the arbitration has been demanded, the nomination shall be made by the President for the time being of the South African Institute of Chartered Accountants, within 7 (seven) days after the Parties have so failed to agree.</span>
                              </li>
                              <li className="paragraph">
                                20.1.7. <span>The arbitrator shall determine which Party shall pay the costs of and incidental to the arbitration or, if more than 1 (one) Party is to contribute, the ratio of their respective contributions, and the scale on which such costs are to be paid.</span>
                              </li>
                              <li className="paragraph">
                                20.1.8. <span>Subject to each Party’s rights of appeal and review, in accordance with the AFSA Rules, the Parties irrevocably agree that the decision of the arbitrator shall be final and binding on them, shall be carried into effect, and shall be capable of being made an order of any court of competent jurisdiction.</span>
                              </li>
                              <li>
                                20.1.9. <span>The provisions of this clause 20.1 :</span>
                                <ul style={{marginLeft:60}}>
                                  <li className="paragraph">
                                    20.1.9.1. <span><p>constitute irrevocable consent by the Parties to any proceedings in terms of this clause 20.1  and no Party shall be entitled to withdraw therefrom or claim at any such proceedings that it/he/she is not bound by such provisions;</p></span>
                                  </li>
                                  <li className="paragraph">
                                    20.1.9.2. <span><p>are severable from the rest of the Terms and shall remain in effect despite the termination, or invalidity for any reason, of these Terms; and</p></span>
                                  </li>
                                  <li className="paragraph">
                                    20.1.9.3. <span><p>shall not preclude any Party from obtaining interim relief on an urgent basis from any court of competent jurisdiction pending the decision of the arbitrator.</p></span>
                                  </li>
                                </ul>
                              </li>
                              <li className="paragraph">
                                20.1.10. <span><p>This clause shall be severable from the rest of these Terms and therefore shall remain effective between the Parties after the Terms have terminated.</p></span>
                              </li>
                            </ul>
                          </li>
                          <li>
                            20.2  <b>Assignment and novation</b>: 
                            <ul>
                              <li className="paragraph mt-4">
                                20.2.1. <span><p>We may assign or novate any of our rights or obligations under these Terms without a User’s consent. A User may not assign or novate any of his/her rights.</p></span>
                              </li>
                            </ul>
                          </li>
                          <li>
                            20.3  <b>General</b>
                            <ul>
                              <li className="paragraph mt-4">
                                20.3.1. <span><p>To the extent permitted by law, these Terms shall be governed by and be construed in accordance with South African law, and any dispute arising out of these Terms shall be submitted to the competent South African courts having the requisite jurisdiction to hear the matter.</p></span>
                              </li>
                              <li>
                                20.3.2. <span>To the extent necessary and/or possible, you consent to the non-exclusive jurisdiction of the Pietermaritzburg High Court or an alternative appropriate South African court seized with appropriate jurisdiction in all disputes arising out of the Terms.</span>
                              </li>
                            </ul>
                          </li>
                          <li>
                            20.4  <b>Severance</b>
                            <ul>
                              <li className="paragraph mt-4">
                                20.4.1. <span><p> If any of these provisions are deemed invalid or unenforceable for any reason (including, but not limited to the exclusions and limitations set out above), then the invalid or unenforceable provision will be severed from these Terms and the remaining terms will continue to apply. Failure by us to enforce any of the provisions set out in these Terms, or failure to exercise any option to terminate, shall not be construed as a waiver of such provisions and shall not affect the validity of these Terms, or the right thereafter to enforce each and every provision.</p></span>
                              </li>
                            </ul>
                          </li>
                          <li>
                            20.5  <b>Domicilium Citandi Et Executandi and Contact Information</b>
                            <ul>
                              <li>
                                <div className="paragraph mt-4">
                                20.5.1. <span> <p>The User and BeyondBox choose as their respective domicilium citandi et executandi for the purpose of legal proceedings and for the purpose of giving or sending any notice provided for or necessary of these Terms, the following:</p></span>
                                </div>
                                <ul style={{marginLeft:60}}>
                                  <li style={{display:"flex",justifyContent:"start"}}>
                                    20.5.1.1. 
                                    <span><p>
                                      BeyondBox (Pty) Ltd <br/>
                                      6 Brechin Place, Montrose<br/>
                                      Pietermaritzburg<br/>
                                      KwaZulu-Natal<br/>
                                      3201 <br/>
                                      South Africa<br/>
                                      info@beyondbox.org 
                                      </p>
                                    </span>
                                  </li>
                                  <li style={{display:"flex",justifyContent:"start"}}>
                                    20.5.1.2. 
                                    <span><p>User:<br/>
                                      The address as provided when registering for the Services.
                                      </p>
                                    </span>
                                  </li>
                                </ul>
                              </li>
                              <li className="paragraph">
                                20.5.2. <span><p> A User and/or BeyondBox may change their domicilium to any other physical address or email address by written notice to the other to that effect. Such change of address will be effective 7 (seven) days after receipt of notice of change of domicilium.</p></span>
                              </li>
                              <li>
                                20.5.3. <span>All notices to be given in terms of these Terms will: </span>
                                <ul style={{marginLeft:60}}>
                                  <li className="mt-4">
                                    20.5.3.1. <span>be given in writing;</span>
                                  </li>
                                  <li>
                                    20.5.3.2. <span>be delivered or sent by email; and</span>
                                  </li>
                                  <li>
                                    20.5.3.3. <span>be presumed to have been received on the date of dispatch.</span>
                                  </li>
                                </ul>
                              </li>
                              <li className="paragraph">
                                20.5.4. <span><p>Notwithstanding the above, any notice actually received by the Party to whom notice is addressed will be deemed to have been properly given and received, notwithstanding that such notice has not been given in accordance with the provisions of this clause.</p></span>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <h4>21.<b>Preparation</b></h4>
                        <ul>
                          <span>These Terms have been custom created for BeyondBox by the good folks at Legal Legends <a href="www.legallegends.co.za" target="_blank">www.legallegends.co.za</a>.</span>
                        </ul>
                      </li>  
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    </>
  );
};

export default TermAndConditions;